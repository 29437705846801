import type { FunctionComponent } from 'react'

import { Theme } from '@sporza/config'
import Hidden from '@sporza/design-system/atoms/hidden'
import Title, { TitleElement, TitleSize } from '@sporza/design-system/atoms/title'
import Cell from '@sporza/design-system/organisms/cell'
import Grid from '@sporza/design-system/organisms/grid'
import { ComponentType, mapToComponent } from '@sporza/services/component-mapper'
import clsx from 'clsx'

import { PageType } from '~/config'
import { Layout } from '~/layouts'

import styles from './default.module.scss'

interface DefaultLayoutProps {
  title: string,
  header: any[],
  main: any[],
  mainBody: any[],
  aside: any[],
  apiBaseUrl?: string,
  designSystemBaseUrl?: string,
  bffBaseUrl?: string,
  classNames?: string
  pageType?: string
  theme?: string
  accessibilityTitle?: string
  accessibilitySubTitle?: string
}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = (
  {
    title,
    header: headerItems = [],
    main: mainItems = [],
    mainBody: mainBodyItems = [],
    aside: asideItems = [],
    apiBaseUrl,
    designSystemBaseUrl,
    bffBaseUrl,
    classNames,
    pageType,
    theme,
    accessibilityTitle,
    accessibilitySubTitle
  }
) => {
  const headerChildren = !!headerItems?.length && <Grid>
    <Cell size={4}>
      {headerItems.map((item: any) => mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))}
    </Cell>
  </Grid>

  const showTitle = !headerItems?.some((item: any) => item.componentType === ComponentType.Bio) && pageType !== PageType.Promo

  mainItems = mainItems.map(item => {
    if (item.componentType === 'text') {
      return {
        ...item,
        componentProps: {
          ...item.componentProps,
          className: `${item.componentProps.className || ''} ${styles.paragraph}`.trim()
        }
      }
    }

    return item
  })

  const titleContent = accessibilityTitle && accessibilitySubTitle
    ? <Title size={TitleSize.Large}>{title}<Hidden tag={TitleElement.H2}>{accessibilitySubTitle}</Hidden></Title>
    : <Title size={TitleSize.Large} tag={accessibilityTitle ? TitleElement.H2 : TitleElement.H1}>{title}</Title>

  return <Layout
    title={pageType === PageType.Promo ? title : undefined}
    accessibilityTitle={accessibilityTitle}
    headerChildren={headerChildren}
    className={clsx(
      classNames
    )}
  >
    <Grid collapse>
      <Cell size={asideItems?.length ? 3 : 4}>
        <main id={!headerChildren ? 'content' : undefined} className={theme === Theme.GenericEddies ? styles.main : undefined}>
          {showTitle && titleContent}
          {
            [
              ...mainItems,
              ...mainBodyItems
            ].map((item: any) => mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))
          }
        </main>
      </Cell>
      {!!asideItems?.length && <Cell size={1}>
        <aside>
          {asideItems.map((item: any) => mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))}
        </aside>
      </Cell>}
    </Grid>
  </Layout>
}

export {
  DefaultLayout
}
